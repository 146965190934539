import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import "../styles/contact.css"

const Contact = () => {
  // add form submission event listener
  return (
    <div>
      <section className="my-5">
            <div className="container">
              <div className="row align-items-center">
                <div className="align-self-start col-xl-7 col-lg-6">
                  <h1 className="mt-4 mb-5">Contact Information</h1>
                  <div>
                    <div className="d-flex align-items-center mb-4">
                      <FontAwesomeIcon className="icon-color align-self-start pt-3" icon={faLocationDot} size="xl"/>
                      <div className="ps-4">
                        <div className="fw-bold mb-1 fs-5">Our Location</div>
                        <div>1790 Hughes Landing Blvd., Ste. 390</div>
                        <div>The Woodlands, TX  77380</div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-4">
                      <FontAwesomeIcon className="icon-color align-self-start pt-3" icon={faPhone} size="xl"/>
                      <div className="ps-3">
                        <div className="fw-bold mb-1 fs-5">Phone</div>
                        <div>281-849-4140</div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-4">
                      <FontAwesomeIcon className="icon-color align-self-start pt-3" icon={faEnvelope} size="xl"/>
                      <div className="ps-3">
                        <div className="fw-bold mb-1 fs-5">Email</div>
                        <div><a href="mailto:info@daytonaresources.com">info@daytonaresources.com</a></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
    </div>
  )
}

export default Contact