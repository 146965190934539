import {Link, useLocation} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import "../styles/navbar.css";

const Navbar = () => {
  const location = useLocation();
  return (
    <header>
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <a className="navbar-brand ms-3" href="/">
            <img id="logo-nav" src="/logo-white.svg" alt="Logo" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarContent">
            <ul className="nav-links navbar-nav d-flex ms-auto me-3">
              <li className="nav-item">
                <Link className={"nav-link " + (location.pathname === "/" ? "active" : "")} to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link className={"nav-link " + (location.pathname === "/about" ? "active" : "")} to="/about">About</Link>
              </li>
              <li className="nav-item">
                <Link className={"nav-link " + (location.pathname === "/management" ? "active" : "")} to="/management">Management</Link>
              </li>
              <li className="nav-item">
                <Link className={"nav-link " + (location.pathname === "/contact" ? "active" : "")} to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Navbar