import React from "react"
import "../styles/about.css"

const About = () => {
  return (
    <div>
      <div className="d-flex about-container">
        <article className="text-center p-5">
          <h1 className="my-5">Daytona Resources LLC owns and manages oil and gas minerals and royalties in the Midland Basin</h1>
        </article>
      </div>
    </div>
  )
}

export default About