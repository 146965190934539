import React from "react"
import { Link } from "react-router-dom";
import "../styles/home.css"

const Home = () => {
  return (
    <main>
      <section className="main-section position-relative">
        <img className="section-image" src="/main-home-section.png" alt="Home" />
        <div className="content text-center position-absolute top-50 start-50 translate-middle">
          <h1 className="my-3 fw-semibold"></h1>
        </div>
      </section>
    </main>
  )
}

export default Home