import React from "react"
import "../styles/Management.css"

const Management = () => {
  return (
    <div>
        <div className="container mt-5">
            <h1 className="text-center my-5">Our Leadership</h1>
            <div className="row">
                <article className="col-md-12 col-lg-4">
                    <h3>Bryan Gunderson</h3>
                    <h4>Chief Executive Officer</h4>
                    <p>Bryan Gunderson is the Chief Executive Officer of Daytona Resources LLC.  Previous to Daytona, Mr. Gunderson was the President and Chief Executive Officer of Falcon Minerals (NASDAQ: FLMN).  Prior to his time as Falcon’s CEO, he served as the company’s Chief Financial Officer.  Prior to Falcon, Mr. Gunderson held various other senior positions across the energy industry.  He holds a BA from Bard College and an MBA from The Wharton School</p>
                </article>
                <article className="col-md-12 col-lg-4">
                    <h3>Becky Bayless</h3>
                    <h4>Chief Financial Officer</h4>
                    <p>Becky Bayless is the Chief Financial Officer of Daytona Resources LLC. Previous to Daytona, Ms. Bayless
was the SVP, Chief Administrative Officer and Treasurer of Indigo Natural Resources and served as EVP,
Chief Financial Officer of Indigo Minerals LLC. Prior to Indigo, Ms. Bayless held various other senior
finance and accounting positions with over 25 years of experience in the oil and gas industry. She is on
the board of directors of Riley Exploration Permian (NASDAQ: REPX) and serves on their Audit
Committee and is Chairperson of their Nominating and Governance Committee. She is currently on the
Board of Trustees for The John Cooper School, The Club at Carlton Woods, and The Houston Producers
Forum. Within these organizations she also leads or serves on the finance committees for each of them.
She is a licensed CPA in the state of Texas and holds a B.S. in Accounting from Arkansas State University.</p>
                </article>
            </div>
        </div>
    </div>
  )
}

export default Management